import { PrepressCraftsService } from '@/api';

import { DialogMixin } from '@/mixins/dialog';
import { PublicApproveStatusEnum, ResourceStatusEnum } from '@/resource/enum';
import { PrepressCraftsDetails } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'PrepressDetails',
  components: {}
})
export default class PrepressDetails extends mixins(DialogMixin) {
  @Prop({
    required: true,
    type: Number,
    default: null
  })
  public id!: number | null;

  public disabled: boolean = true;
  public dialogVisible: boolean = false;

  public info: PrepressCraftsDetails = {} as PrepressCraftsDetails;
  private get getApproveStatusI18Key(): string {
    return translation('publicApproveStatus.' + PublicApproveStatusEnum[this.info.approveStatus]);
  }
  private get getStatusI18Key(): string {
    let res;
    switch (this.info?.enableStatus) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return translation(res);
  }

  public dialogOpen(): void {
    this.title = 'prepressCrafts.title';
    this.getDetails();
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
  }

  public getDetails(): void {
    if (!this.id) return;

    PrepressCraftsService.getById(this.id!)
      .then((res: PrepressCraftsDetails) => {
        this.info = res;
      })
      .catch(err => {
        messageError(err);
      });
  }
}
