import { PrepressCraftsService } from '@/api';

import { DialogMixin } from '@/mixins/dialog';
import { PrepressCraftsResource } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { messageError, translation } from '@/utils';

import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AddPrepress',
  components: {}
})
export default class AddPrepress extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: PrepressCraftsResource | null;
  public dialogVisible: boolean = false;
  public valueType: string = '';

  public form: PrepressCraftsResource = {
    id: null,
    code: '',
    name: '',
    factor: 1,
    remark: ''
  };

  public resourceFormRules = {
    code: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('database.inputCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          PrepressCraftsService.checkCode(value, this.form?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.codeRepeat'));
                return;
              }
              callback();
            })
            .catch((error: any) => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('database.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          PrepressCraftsService.checkName(value, this.form.id!)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.nameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    type: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(`${translation('common.select')}${translation('prepressCrafts.type')}`));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    factor: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value && value !== 0) {
            callback(new Error(`${translation('common.input')}${translation('prepressCrafts.factor')}`));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public prepressCraftsTypeOptions: NormalSelectOptions = [];

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'prepressCrafts.title';
      this.$nextTick(() => {
        Object.assign(this.form, this.position);
      });
      return;
    }
    this.operationType = 'add';
    this.title = 'prepressCrafts.title';
    Object.assign(this.form, {
      id: null,
      code: '',
      name: '',
      factor: 1,
      remark: ''
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.form as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      console.log(valid);
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await PrepressCraftsService.post({ ...this.form } as any);
          Message.success(translation('operationRes.operationSuccess'));
          this.$emit('operate-success');
          this.closeDialog();
          return;
        }
        await PrepressCraftsService.put({ ...this.form } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success', this.form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
