import { render, staticRenderFns } from "./prepress.vue?vue&type=template&id=1afd66a5&scoped=true&"
import script from "./prepress.ts?vue&type=script&lang=ts&"
export * from "./prepress.ts?vue&type=script&lang=ts&"
import style0 from "./prepress.scoped.scss?vue&type=style&index=0&id=1afd66a5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1afd66a5",
  null
  
)

export default component.exports